@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: local('Ubuntu'), local('Roboto-Regular'),
    url(./assets/fonts/ubuntu.ttf) format('truetype');
}

body {
  margin: 0;
}

::selection {
  background: #3f51b5; /* WebKit/Blink Browsers */
  color: #f48fb1;
}
::-moz-selection {
  background: #3f51b5; /* Gecko Browsers */
  color: #f48fb1;
}

.sr-only {
  display: none;
}
